import React, { Fragment } from 'react';
import { Icon } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { getFormattedNumber } from '@ecg-marktplaats/aurora-js-formatters';

import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import Heading from '@/client/components/FilterSidebar/atoms/Heading';
import LinkGenerator from '@/client/components/LinkGenerator/LinkGenerator';
import trackingKeys from '@/client/constants/analytics/trackingKeys';

const BODY_ICON_NAMES = {
  481: 'body-hatchback',
  482: 'body-mpv',
  483: 'body-sedan',
  484: 'body-station',
  485: 'body-cabriolet',
  486: 'body-coupe',
  487: 'body-other',
  488: 'body-suv',
};

type TProps = {
  searchRequestObject: TSearchRequestObject;
  inputKey: string;
  label: string;
  attributeGroup: TAttributeValue[];
  preventScroll?: boolean;
};

const AttributeGroupMultiSelectButtonsFacet = ({
  label,
  attributeGroup,
  inputKey,
  searchRequestObject,
  preventScroll,
}: TProps) => {
  const { t } = useI18nContext();
  const showHistogramCount = !attributeGroup.some((a) => a.selected);
  const title = t(`facets.${inputKey}`, label);

  return (
    <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter' })}>
      <Heading.H5>{title}</Heading.H5>
      <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter-attributeButtons' })}>
        {attributeGroup.map(
          ({ attributeValueKey, attributeValueLabel, histogramCount, attributeValueId, selected }) => {
            const attrLabel = attributeValueLabel || '';
            const facetLabel = t(`facets.${attributeValueKey}`, attrLabel);
            const attributes = {};
            attributes[inputKey] = {
              attributeValueKey,
              attributeValueId: attributeValueId || null,
              attributeValueLabel: facetLabel,
            };
            const disabled = showHistogramCount ? !histogramCount : false;

            return (
              <Fragment key={attributeValueKey}>
                {disabled && (
                  <div
                    className={getSuitCSSClasses({
                      namespace: 'hz',
                      name: 'Filter-attribute-button',
                      modifiers: [selected ? 'selected' : ''],
                    })}
                  >
                    <Icon name={BODY_ICON_NAMES[attributeValueId]} size="l" color="border-strong" />
                    <span className="buttonText">
                      <span className="hz-Text buttonLabel u-colorBorderStrong">{facetLabel}</span>
                      {showHistogramCount && (histogramCount ?? 0) > 0 && (
                        <span className="buttonCounter u-colorTextTertiary">
                          ({getFormattedNumber(histogramCount)})
                        </span>
                      )}
                    </span>
                  </div>
                )}
                {!disabled && (
                  <LinkGenerator
                    key={attributeValueKey}
                    searchRequestObject={searchRequestObject}
                    attributes={attributes}
                    isSelected={selected}
                    className={getSuitCSSClasses({
                      namespace: 'hz',
                      name: 'Filter-attribute-button',
                      modifiers: [selected ? 'selected' : ''],
                    })}
                    eventAction={trackingKeys.FILTER.EVENT_ACTION}
                    eventLabel={attributeValueKey}
                    preventScroll={preventScroll}
                  >
                    <Icon name={BODY_ICON_NAMES[attributeValueId]} size="l" />
                    <span className="buttonText">
                      <span className="hz-Text buttonLabel">{facetLabel}</span>
                      {showHistogramCount && (histogramCount ?? 0) > 0 && (
                        <span className="buttonCounter u-colorTextTertiary">
                          ({getFormattedNumber(histogramCount)})
                        </span>
                      )}
                    </span>
                  </LinkGenerator>
                )}
              </Fragment>
            );
          },
        )}
      </div>
    </div>
  );
};

export default AttributeGroupMultiSelectButtonsFacet;
