import React, { ReactNode } from 'react';
import { MaskedLink } from '@hz-design-system/web-ui';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import ListingVerifiedSellerBadge from '@/client/components/Listings/atoms/ListingVerifiedSellerBadge';

interface ListingSellerNameProps {
  children: ReactNode;
  link: string;
  isVerifiedSeller: boolean;
  as?: string;
  linkAs?: string;
}

const ListingSellerName: React.FC<ListingSellerNameProps> = ({
  children,
  link,
  isVerifiedSeller,
  as = getSuitCSSClasses({ namespace: 'hz', name: 'Listing-seller-name-container' }),
  linkAs = getSuitCSSClasses({ namespace: 'hz', name: 'TextLink' }),
}) => {
  return (
    <span className={as}>
      <MaskedLink className={linkAs} href={link}>
        <span className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-seller-name' })}>{children}</span>
      </MaskedLink>
      {isVerifiedSeller && <ListingVerifiedSellerBadge />}
    </span>
  );
};

export default ListingSellerName;
