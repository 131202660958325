import React, { useContext } from 'react';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';

import ViewControlGroup from '@/client/components/ViewControls/atoms/ViewControlGroup';
import SaveSearchContainer from '@/client/components/molecules/SaveSearchContainer';
import ViewOptionsControl from '@/client/components/ViewControls/molecules/ViewOptionsControl';
import SorterControl from '@/client/components/ViewControls/molecules/SorterControl';
import useViewControls from '@/client/hooks/useViewControls';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import experiments from '@/utils/labs/experiments';
import CATEGORIES_L1 from '@/constants/categories';

const classes = {
  root: getSuitCSSClasses({ namespace: 'hz', name: 'ViewControl' }),
};

export type TViewControlsProps = {
  searchRequestObject: TSearchRequestObject;
  sortOptions: TSortOption[];
  setDisabled: (isDisabled: boolean) => void;
  isDisabled: boolean;
  pushNotification: (type: string, message: React.ReactElement) => void;
  isSoiPage: boolean;
  isAllowSavedSearch: boolean;
};

const ViewControls = ({
  sortOptions,
  searchRequestObject,
  setDisabled,
  isDisabled,
  pushNotification,
  isSoiPage,
  isAllowSavedSearch,
}: TViewControlsProps) => {
  const { labsConfig } = useContext(EnvironmentContext);
  const { isFetchResultsOnceEnabled, isFetchResultsOnceAllCatEnabled } = experiments({ labsConfig });
  const withAllAttributes =
    isFetchResultsOnceAllCatEnabled ||
    (isFetchResultsOnceEnabled && searchRequestObject.categories?.l1Category?.id === CATEGORIES_L1.DAMES_KLEREN);
  const { handleViewOptionsChange } = useViewControls(searchRequestObject, isAllowSavedSearch, withAllAttributes);
  const { viewOptions } = searchRequestObject;

  return (
    <div className={classes.root}>
      <ViewControlGroup type="functional-options">
        {!isSoiPage && (
          <SaveSearchContainer
            setDisabled={setDisabled}
            isDisabled={isDisabled}
            pushNotification={pushNotification}
            searchRequestObject={searchRequestObject}
            smallButton
            className="hz-hide-desktop"
          />
        )}
      </ViewControlGroup>
      <ViewOptionsControl kind={viewOptions.kind} onChange={handleViewOptionsChange} />
      <ViewControlGroup type="functional-options">
        <SorterControl
          searchRequestObject={searchRequestObject}
          sortOptions={sortOptions}
          withAllAttributes={withAllAttributes}
        />
      </ViewControlGroup>
    </div>
  );
};

export default ViewControls;
