const getImageObjectSchema = (listings, locale) => {
  type listing = {
    title: string;
    itemId: string;
    description: string;
    sellerInformation: TSellerInformation;
    pictures?: {
      extraExtraLargeUrl: string;
    }[];
  };
  const creditTextMap = {
    'nl-NL': 'Marktplaats',
    'nl-BE': '2dehands',
    'fr-BE': '2ememain',
  };
  const creditText = creditTextMap[locale] || '';

  const filteredListings = listings?.filter((listing: listing) => listing.pictures && listing.pictures.length > 0);
  if (!filteredListings.length) return;

  return filteredListings?.map((listing: listing) => {
    const { title, description, sellerInformation, pictures } = listing;

    return {
      '@context': 'https://schema.org',
      '@type': 'ImageObject',
      creditText: creditText,
      name: title,
      description: description,
      contentUrl: pictures?.[0].extraExtraLargeUrl,
      creator: {
        '@type': 'Person',
        name: sellerInformation.sellerName,
      },
      representativeOfPage: false,
    };
  });
};

export default getImageObjectSchema;
