import dataLayerKeys from '@/server/constants/dataLayer';

interface VurlGeneratorProps {
  categories?: TCategories;
  query?: string;
  isSOI?: boolean;
  isZRP?: boolean;
  hasAttributes?: boolean;
}

function buildCategoriesPart(categories?: TCategories): string {
  if (!categories) return '';

  const l1Part = categories.l1Category ? `/${categories.l1Category.id}_${categories.l1Category.key}` : '';
  const l2Part = categories.l2Category ? `/${categories.l2Category.id}_${categories.l2Category.key}` : '';

  return l1Part + l2Part;
}

function buildCategoryKey(CATEGORY_KEY: string, categories?: TCategories, query?: string): string {
  if (!query) return '';

  if (categories?.l2Category) {
    return `${CATEGORY_KEY}=${categories.l2Category.id}_${categories.l2Category.key}`;
  }

  if (categories?.l1Category) {
    return `${CATEGORY_KEY}=${categories.l1Category.id}_${categories.l1Category.key}`;
  }

  return `${CATEGORY_KEY}=0`;
}

const vurlGenerator = ({ categories, query, isSOI, isZRP, hasAttributes }: VurlGeneratorProps): string => {
  const {
    PAGE_KEY,
    PAGE_TYPE_SOI,
    QUERY_KEY,
    PAGE_TYPE_SEARCH,
    PAGE_TYPE_ZRP,
    CATEGORY_KEY,
    PAGE_TYPE_BROWSE,
    ATTRIBUTE_KEY,
  } = dataLayerKeys;

  if (isSOI) {
    return `/${PAGE_KEY}/${PAGE_TYPE_SOI}`;
  }

  const pageType = query ? PAGE_TYPE_SEARCH : PAGE_TYPE_BROWSE;
  const attributePart = hasAttributes ? `/${ATTRIBUTE_KEY}` : '';
  const queryPart = query ? `&${QUERY_KEY}=${query}` : '';

  const categoriesPart = buildCategoriesPart(categories);

  const currentCategoryKey = buildCategoryKey(CATEGORY_KEY, categories, query);

  const queryStringSeperator = currentCategoryKey || queryPart ? '?' : '';

  const basePath = `/${PAGE_KEY}${categoriesPart}/${pageType}`;

  if (isZRP) {
    return `${basePath}/${PAGE_TYPE_ZRP}${queryStringSeperator}${currentCategoryKey}${queryPart}`;
  }

  return `${basePath}${attributePart}${queryStringSeperator}${currentCategoryKey}${queryPart}`;
};

export default vurlGenerator;
