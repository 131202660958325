const excludeTextAttributesFromNormalizing = (urlPart: string) => {
  const textAttributeSegment = '/ta/';
  if (urlPart.includes(textAttributeSegment)) {
    const beforeTextAttributePart = urlPart.substring(0, urlPart.indexOf(textAttributeSegment));
    const textAttributeIndex = urlPart.indexOf(textAttributeSegment) + textAttributeSegment.length; // index of '/ta/' in the path

    const textAttributeKeys = `${urlPart.substring(textAttributeIndex, urlPart.indexOf('/', textAttributeIndex))}/`; // actual query after /ta/
    const textAttributeKeysIndex = urlPart.indexOf(textAttributeKeys) + textAttributeKeys.length;
    const textAttributeValues = `${urlPart.substring(
      textAttributeKeysIndex,
      urlPart.indexOf('/', textAttributeKeysIndex),
    )}/`;

    const afterTextAttributePart = urlPart.substring(
      textAttributeIndex + textAttributeKeys.length + textAttributeValues.length,
    );

    return (
      beforeTextAttributePart.replace(/ /g, '+').toLowerCase() +
      textAttributeSegment +
      textAttributeKeys +
      textAttributeValues +
      afterTextAttributePart.replace(/ /g, '+').toLowerCase()
    );
  }

  return urlPart.replace(/ /g, '+').toLowerCase();
};

export default excludeTextAttributesFromNormalizing;
