import React, { useState, useEffect } from 'react';
import { getFormattedNumber } from '@ecg-marktplaats/aurora-js-formatters';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Card, TextField, BasicModalWithFooter, Icon } from '@hz-design-system/web-ui';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';

import InlineInput from '@/client/components/FilterSidebar/atoms/InlineInput';
import trackEvent from '@/client/utils/analytics/trackEvent';

type TAttributeDialogProps = {
  attributes;
  title: string;
  inputKey: string;
  searchRequestObject: TSearchRequestObject;
  handleClose: () => void;
  showHistogramCount: boolean;
  totalResults: number;
  eventLabel: string;
  isMobile: boolean;
};

const AttributeDialog = ({
  attributes,
  title,
  inputKey,
  searchRequestObject,
  handleClose,
  showHistogramCount,
  totalResults,
  eventLabel,
  isMobile,
}: TAttributeDialogProps) => {
  const { t } = useI18nContext();
  const [newSearchQuery, setNewSearchQuery] = useState('');
  const [filteredAttributes, setFilteredAttributes] = useState(attributes);

  const handleChange = (event) => {
    const value = event.target.value;
    setNewSearchQuery(value);
    setFilteredAttributes(
      attributes.filter((attribute) => attribute.attributeValueKey.toLowerCase().indexOf(value.toLowerCase()) > -1),
    );

    // Track if text attribute search is used
    trackEvent({
      eventAction: 'TextSearchIsUsed',
      eventLabel,
    });
  };

  const trackTextAttributeSelection = () => {
    trackEvent({
      eventAction: 'TextAttributeClickInModal',
      eventLabel,
    });
  };

  useEffect(() => {
    setFilteredAttributes(
      attributes.filter(
        (attribute) => attribute.attributeValueKey.toLowerCase().indexOf(newSearchQuery.toLowerCase()) > -1,
      ),
    );
  }, [attributes, newSearchQuery]);

  if (attributes.length === 0) {
    return null;
  }

  return (
    <BasicModalWithFooter
      className="textAttribute-modal"
      title={title}
      buttonsAlignmentMobile="stacked"
      onClose={handleClose}
      onBack={handleClose}
      headerAction={isMobile ? 'BACK' : 'CLOSE'}
      confirm={{
        text: (
          <>
            <span className="textAttribute-desktopButton">
              {' '}
              {t('facets.showResults', { count: totalResults, totalResults: getFormattedNumber(totalResults) })}{' '}
            </span>
            <span className="textAttribute-mobileButton"> {t('facets.textAttributeBack')} </span>
          </>
        ),
        as: 'button',
        onClick: handleClose,
      }}
    >
      <div className="hz-TextField-formControl textAttribute-searchInput">
        <span className="hz-TextField-prefix">
          <Icon name="search" size="md" />
        </span>
        <TextField
          className="hz-TextField-input"
          name="PrefixedTextField"
          type="text"
          onChange={handleChange}
          value={newSearchQuery}
          placeholder={t('facets.textAttributeSearch', { facet: title.toLowerCase() })}
        />
      </div>

      <Card
        className={`${getSuitCSSClasses({ namespace: 'hz', name: 'TextAttribute-content' })} ${getSuitCSSClasses({ namespace: 'hz', name: 'Filter' })}`}
      >
        {filteredAttributes.map(
          ({ attributeValueKey, attributeValueLabel, histogramCount, attributeValueId, selected }) => {
            // translations first !
            const attrLabel = attributeValueLabel || '';
            const facetLabel = t(`facets.${attributeValueKey}`, attrLabel);
            const attributeValues = {};
            attributeValues[inputKey] = {
              attributeValueKey,
              attributeValueId: attributeValueId || null,
              attributeValueLabel: facetLabel,
            };
            const disabled = showHistogramCount ? !histogramCount : false;
            return (
              <InlineInput
                key={attributeValueKey}
                count={showHistogramCount ? histogramCount : null}
                valueKey={attributeValueKey}
                attributeKey={inputKey}
                label={facetLabel}
                type="checkbox"
                checked={selected}
                attributes={attributeValues}
                disabled={disabled}
                searchRequestObject={searchRequestObject}
                onClick={trackTextAttributeSelection}
              />
            );
          },
        )}
      </Card>
    </BasicModalWithFooter>
  );
};

export default AttributeDialog;
