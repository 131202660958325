import React from 'react';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Icon, PrimaryButton } from '@hz-design-system/web-ui';

const SSOIZeroResultsBox = () => {
  const { t } = useI18nContext();

  return (
    <div className="hz-SSOIZeroResultsBox-wrap">
      <div className="hz-SSOIZeroResultsBox-container">
        <div className="hz-SSOIZeroResultsBox-title">{t('messages.own_soi.no_ads.title')}</div>
        <div className="hz-SSOIZeroResultsBox-subtitle">{t('messages.own_soi.no_ads.subtitle')}</div>
        <div>
          <PrimaryButton as="a" rel="nofollow" href="/plaats" className="hz-SSOIZeroResultsBox-button">
            <Icon name="pin" />
            <Trans className="hz-floatingButton-text" tagName="span" i18nKey="messages.own_soi.no_ads.button" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default SSOIZeroResultsBox;
