const splitHashOptions = (hashString: string) => {
  // Remove '#' and split string using the delimiter
  const hashOptions = decodeURIComponent(hashString).substring(1).split('|');

  // Split params in the hash, and remove invalid params
  return hashOptions
    .map((hashParam) => {
      const hashParamArr = hashParam.split(':');
      const key = hashParamArr[0];
      const value = hashParamArr[1];

      if (!key || !value) {
        return null;
      }

      return { key, value };
    })
    .filter((i) => i); // Filter out undefined values
};

export default splitHashOptions;
