/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { getFormattedNumber } from '@ecg-marktplaats/aurora-js-formatters';
import LinkGenerator from '@/client/components/LinkGenerator/LinkGenerator';
import trackingKeys from '@/client/constants/analytics/trackingKeys';

import menuIconOpen from './images/collapsible-menu-open-icon.png';
import menuIconClosed from './images/collapsible-menu-closed-icon.png';
import CATEGORIES_L1 from '@/constants/categories';

type TListItemOneProps = {
  isOpen?: boolean;
  text?: string;
  categories?: {
    l1Category: {
      key: string;
      id: number;
    };
  };
  searchRequestObject: TSearchRequestObject;
  selected?: boolean;
  hidden?: boolean;
};

type TListItemTwoProps = {
  count?: number;
  hidden?: boolean;
  text?: string;
  categories?: {
    l1Category: TCategory;
    l2Category: TCategory;
  };
  searchRequestObject: TSearchRequestObject;
  selected?: boolean;
};

const getLink = (status) => (status === 'open' ? menuIconOpen : menuIconClosed);
const l1CategoryIsCars = (l1categoryId) => {
  return l1categoryId === CATEGORIES_L1.CARS;
};

const ListItemOne = ({ isOpen, text, categories, searchRequestObject, hidden, selected }: TListItemOneProps) => {
  const [status, setStatus] = useState(isOpen ? 'open' : 'closed');
  const removeAllAttributes = !l1CategoryIsCars(categories?.l1Category?.id);

  useEffect(() => {
    setStatus(isOpen ? 'open' : 'closed');
  }, [isOpen]);

  const handleToggle = () => {
    setStatus(status === 'closed' ? 'open' : 'closed');
  };

  return (
    <li
      className={getSuitCSSClasses({
        namespace: 'hz',
        name: 'Level-one',
        modifiers: [status, `${selected ? 'selected' : ''}`],
        custom: `${hidden ? 'hz-hidden' : ''} ${selected ? 'selected' : ''}`,
      })}
    >
      <span className={getSuitCSSClasses({ namespace: 'hz', name: 'State-icon' })}>
        <img suppressHydrationWarning onClick={handleToggle} src={getLink(status)} alt="expand" role="presentation" />
      </span>
      <LinkGenerator
        searchRequestObject={searchRequestObject}
        categories={categories}
        removeAllAttributes={removeAllAttributes}
        className={`category-name${selected ? ' category-name--selected' : ''}`}
        eventAction={trackingKeys.FILTER.EVENT_ACTION}
        eventLabel={trackingKeys.FILTER.EVENT_LABEL.PARENT_CATEGORY}
      >
        {text}
      </LinkGenerator>
    </li>
  );
};

const ListItemTwo = ({ text, count, categories, hidden, searchRequestObject, selected }: TListItemTwoProps) => (
  <li
    className={getSuitCSSClasses({
      namespace: 'hz',
      name: 'Level-two',
      modifiers: [`${selected ? 'selected' : ''}`],
      custom: [`${hidden ? 'hz-hidden' : ''}`],
    })}
  >
    <LinkGenerator
      searchRequestObject={searchRequestObject}
      categories={categories}
      className={`category-name${selected ? ' category-name--selected' : ''}`}
      eventAction={trackingKeys.FILTER.EVENT_ACTION}
      eventLabel={trackingKeys.FILTER.EVENT_LABEL.SINGLE_CATEGORY}
    >
      {text}
      <span className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter-counter' })}>
        ({getFormattedNumber(count)})
      </span>
    </LinkGenerator>
  </li>
);

class TreeItem extends React.Component {
  static LevelOne = ListItemOne;

  static LevelTwo = ListItemTwo;
}

export default TreeItem;
