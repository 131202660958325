import replaceAllFromObject from '@/utils/strings/replaceAllFromObject';
import formatForURL from '@/utils/formatters/formatForURL';
import { logger } from '@lrp/logger';
import { Request } from 'express';

export default function getCanonicalUrl(req: Request, dominantCategory?: TDominantCategory): string {
  try {
    if (!req || !req.res || !req.res.locals) {
      throw new Error('getCanonicalUrl: req.res.locals is undefined');
    }

    const { searchQuery = '', fullRoute, l1Category = {} } = req.res.locals;
    const { hostname, params } = req;

    // Dominant category experiment for l0 searches
    if (
      Object.keys(l1Category).length === 0 &&
      dominantCategory &&
      Object.keys(dominantCategory).length > 0 &&
      searchQuery !== ''
    ) {
      const parentKey = dominantCategory?.parentKey ? `/${dominantCategory.parentKey}` : '';
      return `https://${hostname}/l${parentKey}/${dominantCategory.key}/q/${formatForURL(searchQuery)}/`;
    }

    const replaceObjFromParams = Object.keys(params).reduce((acc, param) => {
      acc[`:${param}`] = params[param];
      return acc;
    }, {});

    const uriFromRoutePath = replaceAllFromObject(fullRoute, {
      ...replaceObjFromParams,
      ':query': formatForURL(searchQuery),
    });

    const url = `https://${hostname}${uriFromRoutePath}`;

    return url.substring(url.length - 1) === '/' ? url : `${url}/`;
  } catch (e) {
    logger.warn('seoTags error', e);
  }

  return '';
}
