const MAX_ATTRIBUTES_BY_ID = 3;
const hasAttributes = ({ attributesById = [], attributesByKey = [], attributeRanges = [] }: TSearchRequestObject) =>
  attributesById.length || attributesByKey.length || attributeRanges.length;

const hasTooManyAttributesById = ({ attributesById = [] }: TSearchRequestObject) =>
  attributesById.length > MAX_ATTRIBUTES_BY_ID;

const hasQuery = (searchRequest: TSearchRequestObject) => searchRequest.searchQuery !== '';

const hasNonValuableAttributes = (searchRequest: TSearchRequestObject) => {
  const nonValuableAttributeKeys = searchRequest.attributes
    ? Object.keys(searchRequest.attributes).filter(
        (key: string) =>
          (searchRequest.seoFriendlyAttributes?.indexOf(key) ?? -1) < 0 &&
          !searchRequest.attributes[key].every((value: TAttributeValueItem) => value.isDefault),
      )
    : [];

  return nonValuableAttributeKeys.length > 0;
};

const getRobotsContent = (
  searchRequestObject: TSearchRequestObject,
  hasResults: boolean,
  isFetchResultsOnceAllCatEnabled: boolean,
) => {
  if (hasTooManyAttributesById(searchRequestObject)) {
    return 'noindex, nofollow';
  }

  if (isFetchResultsOnceAllCatEnabled) {
    return (hasAttributes(searchRequestObject) || hasQuery(searchRequestObject)) &&
      (!hasResults || hasNonValuableAttributes(searchRequestObject))
      ? 'noindex, follow'
      : 'index, follow';
  } else {
    return (hasAttributes(searchRequestObject) || hasQuery(searchRequestObject)) && !hasResults
      ? 'noindex, follow'
      : 'index, follow';
  }
};

export default getRobotsContent;
