/**
 * this import is excluded from client-side build
 * @see package.json:browser
 */
import serverLogger from '@ecg-marktplaats/aurora-node-logging';

/* eslint-disable no-console */
const noopLogger = {
  log: () => {},
  error: () => {},
  info: () => {},
  debug: () => {},
  warn: () => {},
};

const consoleLogger = {
  log: console.log,
  error: console.error,
  info: console.info,
  debug: console.debug,
  warn: console.warn,
};

let instance: any;

if (typeof window !== 'undefined') {
  instance = process.env.NODE_ENV === 'production' ? noopLogger : consoleLogger;
} else {
  instance = serverLogger as any;
}

export default instance;
