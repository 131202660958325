import { useEffect } from 'react';

/**
 * Sorts attributeIds behind the # to avoid duplicate content urls
 */
const useFixFragmentSorting = (isSortingEnabled: boolean) => {
  useEffect(() => {
    if (!isSortingEnabled || typeof window === 'undefined') return;

    const { hash } = window.location;
    if (!hash.includes('f:')) return;

    const match = hash.match(/^(.*#.*f:)([^|]*)(.*)$/);
    if (!match) return;

    const capturedGroupsMatch = match.slice(1);
    const [prefix, values, suffix] = capturedGroupsMatch;
    const sortedValues = values
      .split(',')
      .sort((a, b) => Number(a) - Number(b))
      .join(',');

    const newFragment = `${prefix}${sortedValues}${suffix}`;

    if (values !== sortedValues) {
      const url = new URL(window.location.href);
      url.hash = newFragment;
      window.history.replaceState(null, '', url.toString());
    }
  }, [isSortingEnabled]);
};

export default useFixFragmentSorting;
