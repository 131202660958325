import unescape from 'unescape';
import urlPrefixes from '@/constants/urlPrefixes';
import rangeAttributeKeys from '@/constants/attributes/range';
import hashWords from '@/HOC/constants/hashWords';
import formatPostCode from '@/utils/urlHash/formatPostCode';
import isOnlyDigits from '@/utils/strings/isOnlyDigits';
import { attributeKeys, attributeValues } from '@/constants/attributes/nonStandards';

export interface HashMapperResult {
  attributes: TAttribute[];
  query?: string;
  attributesById: number[];
  attributesByKey: TAttributeByKey[];
  attributeRanges: TAttributeRange[];
  attributeLabels: string[];
  sortOptions: TSortOption;
  distance: {
    postcode: string;
    distanceMeters: number;
  };
  viewOptions: {
    kind: string;
  };
  limit: number;
  asSavedSearch: boolean;
  bypassSpellingSuggestion: boolean;
  traits: string[];
}

const LIMIT = 30;

const validateOfferedSinceValue = (value: string | number) =>
  !!value && (attributeValues[attributeKeys.OFFERED_SINCE].includes(value) || isOnlyDigits(value));

const handlers = {
  handleRange: (key: string, value: string, acc: HashMapperResult) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, attributeKey, rangePart] = key.match(/(.+)(From|To)/) || [];
    if (attributeKey && Object.values(rangeAttributeKeys).includes(attributeKey)) {
      const attributeRange = {
        attributeKey,
        [rangePart.toLowerCase()]: value,
      };
      acc.attributeRanges.push(attributeRange);
    }
  },

  handleQuery: (value: string, acc: HashMapperResult) => {
    acc.query = unescape(value).split('+').map(decodeURIComponent).join(' ');
  },

  handleAttributes: (value: string, acc: HashMapperResult) => {
    acc.attributesById = [...acc.attributesById, ...value.split(',').map(Number)];
  },

  handleOfferedSince: (value: string, acc: HashMapperResult) => {
    if (validateOfferedSinceValue(value)) {
      acc.attributesByKey.push({
        attributeKey: attributeKeys.OFFERED_SINCE,
        attributeValueKey: decodeURIComponent(value),
      });
    }
  },

  handleLanguage: (value: string, acc: HashMapperResult) => {
    const languages = value.split(',').map((lang) => ({
      attributeKey: attributeKeys.LANGUAGE,
      attributeValueKey: decodeURIComponent(lang),
    }));
    acc.attributesByKey = [...acc.attributesByKey, ...languages];
  },

  handleTextAttributes: (value: string, acc: HashMapperResult) => {
    const newAttributes = value.split(urlPrefixes.textAttributesSeparator).flatMap((textAttributes) => {
      const [textAttributeKey, textAttributeValues] = textAttributes.split('=');
      return textAttributeValues.split(',').map((textAttribute) => ({
        attributeKey: textAttributeKey,
        attributeValueKey: decodeURIComponent(textAttribute),
      }));
    });
    acc.attributesByKey = [...acc.attributesByKey, ...newAttributes];
  },
};

const hashMapper = (splitParams): HashMapperResult => {
  const result: HashMapperResult = {
    attributes: [],
    query: undefined,
    attributeLabels: [],
    attributesById: [],
    attributesByKey: [],
    attributeRanges: [],
    sortOptions: {
      sortBy: '',
      sortOrder: '',
      sortAttribute: '',
    },
    distance: {
      postcode: '',
      distanceMeters: 0,
    },
    viewOptions: {
      kind: '',
    },
    asSavedSearch: false,
    bypassSpellingSuggestion: false,
    traits: [],
    limit: 30,
  };

  return splitParams.reduce((acc, { key, value }) => {
    if (!key || !value) {
      return acc;
    }

    // Handle range attributes
    handlers.handleRange(key, value, acc);

    // Handle specific keys
    switch (key) {
      case urlPrefixes.query:
        handlers.handleQuery(value, acc);
        break;
      case urlPrefixes.attributes:
        handlers.handleAttributes(value, acc);
        break;
      case attributeKeys.OFFERED_SINCE:
        handlers.handleOfferedSince(value, acc);
        break;
      case attributeKeys.LANGUAGE:
        handlers.handleLanguage(value, acc);
        break;
      case urlPrefixes.textAttributes:
        handlers.handleTextAttributes(value, acc);
        break;
      case hashWords.SORT_BY:
      case hashWords.SORT_ORDER:
      case hashWords.SORT_ATTRIBUTE:
        acc.sortOptions[key] = value;
        break;
      case hashWords.DISTANCE_METERS:
      case hashWords.POSTCODE:
        acc.distance[key] = key === hashWords.POSTCODE ? formatPostCode(value) : value;
        break;
      case hashWords.VIEW:
        acc.viewOptions.kind = value;
        break;
      case hashWords.LIMIT:
        acc.limit = Number(value) || LIMIT;
        break;
      case urlPrefixes.asSavedSearch:
      case urlPrefixes.bypassSpellingSuggestion:
        acc[key] = value === 'true';
        break;
      case urlPrefixes.traits:
        acc.traits = [value];
        break;
    }

    return acc;
  }, result);
};

export default hashMapper;
