import categoriesToDataLayerCategories from '@/utils/analytics/categoriesToDataLayerCategories';

type Location = {
  distance?: number;
  postcode?: string;
};

type PriceRange = {
  from?: number;
  to?: number;
};

type UpdateDataLayerProps = {
  categories: TCategories;
  dominantCategory?: number;
  location: Location;
  vurl: string;
  pageType: string;
  attributes: TAttribute[];
  query: string;
  pageNumber: number;
  pageSize: number;
  sortOptions: TSortOption;
  totalResultCount: number;
  priceRange?: PriceRange;
  viewKind: string;
  locale: string;
};

const updateDataLayer = ({
  categories,
  dominantCategory,
  location,
  vurl,
  pageType,
  attributes,
  query,
  pageNumber,
  pageSize,
  sortOptions,
  totalResultCount,
  priceRange,
  viewKind,
  locale,
}: UpdateDataLayerProps): void => {
  const newDataLayerObject = {
    c: categoriesToDataLayerCategories(categories),
    l: {
      d: location.distance || 0,
      pcid: location.postcode || '',
    },
    p: {
      vurl,
      t: pageType,
      vt: viewKind,
      lng: locale,
    },
    s: {
      attr: JSON.stringify(attributes),
      kw: query,
      pn: pageNumber,
      ps: pageSize,
      srt: {
        dr: sortOptions.sortOrder,
        f: sortOptions.sortBy,
      },
      tr: totalResultCount,
      ...(priceRange && {
        prc: {
          ...(priceRange.from && { mn: priceRange.from }),
          ...(priceRange.to && { mx: priceRange.to }),
        },
      }),
      ...(Number.isInteger(dominantCategory) && {
        dc: dominantCategory,
      }),
    },
  };

  if (window.ecGa) {
    window.ecGa('pushToDataLayer', newDataLayerObject);
  } else {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(newDataLayerObject);
  }
};

export default updateDataLayer;
