import React, { useEffect, useState } from 'react';
import { Checkbox, Radio } from '@hz-design-system/web-ui';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { getFormattedNumber } from '@ecg-marktplaats/aurora-js-formatters';

import LinkGenerator from '@/client/components/LinkGenerator/LinkGenerator';
import trackingKeys from '@/client/constants/analytics/trackingKeys';

type TInlineInputProps = {
  checked: boolean;
  attributes: { string?: TAttribute };
  type: string;
  valueKey: string;
  attributeKey: string;
  label: string;
  disabled: boolean;
  searchRequestObject: TSearchRequestObject;
  count?: number;
  onClick?: (evt?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  isSingleSelect?: boolean;
  preventScroll?: boolean;
};

const InlineInput = ({
  type,
  valueKey,
  attributeKey,
  label,
  attributes,
  disabled,
  checked,
  count,
  isSingleSelect,
  searchRequestObject,
  onClick = () => {},
  preventScroll,
}: TInlineInputProps) => {
  const [inputState, setInputState] = useState(checked);

  useEffect(() => {
    setInputState(checked);
  }, [checked]);

  const handleLinkCLick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setInputState(!inputState);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      className={getSuitCSSClasses({
        namespace: 'hz',
        name: 'Filter-attribute',
        modifiers: [disabled ? 'no-results' : ''],
      })}
    >
      <label key={valueKey} htmlFor={`${attributeKey}-${valueKey}`}>
        {disabled && (
          <>
            {type === 'radio' && (
              <Radio
                id={`${attributeKey}-${valueKey}`}
                value={valueKey}
                onChange={() => {}}
                isChecked={inputState}
                labelText={label}
                counter={count && count > 0 ? getFormattedNumber(count) : undefined}
                disabled
              />
            )}
            {type === 'checkbox' && (
              <Checkbox
                id={`${attributeKey}-${valueKey}`}
                value={valueKey}
                onChange={() => {}}
                isChecked={inputState}
                labelText={label}
                counter={count && count > 0 ? getFormattedNumber(count) : undefined}
                disabled
              />
            )}
          </>
        )}
        {!disabled && (
          <>
            <LinkGenerator
              searchRequestObject={searchRequestObject}
              attributes={attributes}
              isSingleSelect={isSingleSelect || false}
              isSelected={checked}
              className={getSuitCSSClasses({
                namespace: 'hz',
                name: 'Filter-attribute-text',
              })}
              eventAction={trackingKeys.FILTER.EVENT_ACTION}
              eventLabel={attributeKey}
              onClick={() => handleLinkCLick}
              preventScroll={preventScroll}
            >
              {type === 'checkbox' && (
                <Checkbox
                  id={`${attributeKey}-${valueKey}`}
                  value={valueKey}
                  isChecked={inputState}
                  onChange={() => setInputState(!inputState)}
                  labelText={label}
                  counter={count && count > 0 ? getFormattedNumber(count) : undefined}
                />
              )}
              {type === 'radio' && (
                <Radio
                  id={`${attributeKey}-${valueKey}`}
                  value={valueKey}
                  isChecked={inputState}
                  onChange={() => setInputState(!inputState)}
                  labelText={label}
                  counter={count && count > 0 ? getFormattedNumber(count) : undefined}
                />
              )}
            </LinkGenerator>
          </>
        )}
      </label>
    </div>
  );
};

export default InlineInput;
