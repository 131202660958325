import SEO from '@/constants/seo';

interface PrioritizedAttributes {
  seoFriendlyAttributes: { [key: string]: any };
  hashFriendlyAttributes: { [key: string]: any };
  seoFriendlyTextAttributes: {};
  seoFriendlyTextAttributesRequestObject: {};
}

interface MaskLinkParams {
  seller?: TSeller;
  prioritizedAttributes: PrioritizedAttributes;
  rangeAttributes: any[];
}

const hasRegularHashFriendlyAttributes = (prioritizedAttributes): boolean => {
  const hashFriendlyAttributeKeys = Object.keys(prioritizedAttributes.hashFriendlyAttributes);
  const regularHashFriendlyAttributes = hashFriendlyAttributeKeys.filter(
    (attributeKey) => !SEO.noMaskHashAttributes.has(attributeKey),
  );
  return !!regularHashFriendlyAttributes.length;
};

/**
 * Determines if link should be masked from crawler for crawl optimization reasons
 */
const shouldMaskLinkFromCrawler = ({ seller, prioritizedAttributes, rangeAttributes }: MaskLinkParams): boolean => {
  return hasRegularHashFriendlyAttributes(prioritizedAttributes) || !!rangeAttributes?.length || !!seller;
};

export default shouldMaskLinkFromCrawler;
