import queryString from 'query-string';

import { hashPageName } from './helpers';
import urlBuilderSecret from '@/constants/urlBuilderSecret';

interface EnrichVipUrlParams {
  vipUrl: string;
  trackingData?: string;
  pageLocation?: string;
  correlationId?: string;
}

/**
 * adds tracking data to a vip url if available
 */
const enrichVipUrlWithTrackingData = ({
  vipUrl,
  trackingData,
  pageLocation,
  correlationId = '',
}: EnrichVipUrlParams): string => {
  const { url, query } = queryString.parseUrl(vipUrl);
  let updated = false;

  if (trackingData) {
    query.casData = trackingData;
    updated = true;
  }

  if (pageLocation) {
    query.c = hashPageName({
      name: pageLocation,
      secret: urlBuilderSecret,
    });
    updated = true;
  }

  if (correlationId) {
    query.correlationId = correlationId;
    updated = true;
  }

  if (updated) {
    return `${url}?${queryString.stringify(query)}`;
  }

  return vipUrl;
};

export default enrichVipUrlWithTrackingData;
