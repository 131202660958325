import { attributeKeys } from '@/constants/attributes/nonStandards';
import rangeConstants from '@/constants/attributes/range';
import { SORT_BY, SORT_ORDER } from '@/constants/sorting';
import isSsr from '@/client/utils/isSsr';

const createCategoryData = (categories) => {
  const categoryData: {
    l1CategoryId?: number;
    l1CategoryName?: string;
    l2CategoryId?: number;
    l2CategoryName?: string;
  } = {};

  if (categories) {
    if (categories.l1Category) {
      categoryData.l1CategoryId = categories.l1Category.id;
      categoryData.l1CategoryName = categories.l1Category.fullName;
    }

    if (categories.l2Category) {
      categoryData.l2CategoryId = categories.l2Category.id;
      categoryData.l2CategoryName = categories.l2Category.fullName;
    }
  }
  return categoryData;
};

const createRangeData = (rangeAttributes) => {
  const rangeData: {
    searchMinPrice?: number;
    searchMaxPrice?: number;
    searchMinConstructionYear?: number;
    searchMaxConstructionYear?: number;
    searchMinMileage?: number;
    searchMaxMileage?: number;
  } = {};

  if (rangeAttributes && rangeAttributes.length > 0) {
    rangeAttributes.forEach((range) => {
      switch (range.attributeKey) {
        case rangeConstants.PRICE_CENTS:
          if (range.from || range.from === 0) {
            rangeData.searchMinPrice = range.from;
          }
          if (range.to || range.to === 0) {
            rangeData.searchMaxPrice = range.to;
          }
          break;
        case rangeConstants.CONSTRUCTION_YEAR:
          if (range.from) {
            rangeData.searchMinConstructionYear = range.from;
          }
          if (range.to) {
            rangeData.searchMaxConstructionYear = range.to;
          }
          break;
        case rangeConstants.MILEAGE:
          if (range.from || range.from === 0) {
            rangeData.searchMinMileage = range.from;
          }
          if (range.to) {
            rangeData.searchMaxMileage = range.to;
          }
          break;
        default:
        // do nothing
      }
    });
  }
  return rangeData;
};

const createSortType = (sorting) => {
  const { sortBy, sortAttribute } = sorting;

  if (sortAttribute === rangeConstants.MILEAGE) {
    return 'mileage';
  }
  if (sortAttribute === rangeConstants.CONSTRUCTION_YEAR) {
    return 'construction_year';
  }

  if (sortBy === SORT_BY.PRICE) {
    return 'price';
  }
  if (sortBy === SORT_BY.SORT_INDEX) {
    return 'date';
  }
  if (sortBy === SORT_BY.OPTIMIZED) {
    return 'default';
  }

  return null;
};

const createSortOrder = (sorting) => {
  const { sortOrder } = sorting;

  if (sortOrder === SORT_ORDER.INCREASING) {
    return 'increasing';
  }
  if (sortOrder === SORT_ORDER.DECREASING) {
    return 'decreasing';
  }
  return null;
};

const charReplacements = {
  '&': '&amp',
  '>': '&gt;',
  '<': '&lt;',
};
const htmlEscapeJsonString = (string) => string.replace(/[&><]/g, (match) => charReplacements[match]);

const createSearchAttributes = (attributeHierarchy) => {
  const searchAttributes = {};

  Object.keys(attributeHierarchy).forEach((key) => {
    const attributes = attributeHierarchy[key];

    const label = attributes[0].attributeLabel;
    if (label && label !== attributeKeys.OFFERED_SINCE) {
      const valueLabels = attributes
        .map((attribute) => attribute.attributeValueLabel)
        .filter(Boolean)
        .map((attributeValueLabel) => htmlEscapeJsonString(attributeValueLabel));
      if (valueLabels.length > 0) {
        searchAttributes[label] = valueLabels;
      }
    }
  });

  return searchAttributes;
};

const liveSinceMapping = {
  Altijd: 'always',
  'Een week': 'a_week',
  Gisteren: 'yesterday',
  Vandaag: 'today',
};

const createLiveSince = (attributeHierarchy) => {
  const offeredSinceAttribute = attributeHierarchy[attributeKeys.OFFERED_SINCE];
  if (offeredSinceAttribute) {
    return liveSinceMapping[offeredSinceAttribute[0].attributeValueKey] || null;
  }

  return null;
};

const createDevice = () => {
  if (isSsr()) return;
  let device = 'web';

  if (window.matchMedia && window.matchMedia('(max-width: 749px)').matches) {
    device = 'mobile_web';
  }
  return device;
};

const buildEvent = ({
  encryptedUserId,
  tenantContext,
  fingerPrint = '',
  categories,
  query = '',
  postcode = '',
  rangeAttributes = [],
  attributeHierarchy = {},
  sorting = {},
  aamUuid,
}: {
  encryptedUserId?: string;
  tenantContext: TTenantContext;
  fingerPrint?: string;
  categories?: TCategories;
  query?: string;
  postcode?: string;
  rangeAttributes?: {
    attributeKey: string;
    from?: number;
    to?: number;
  }[];
  attributeHierarchy?: TAttributeHierarchy;
  sorting?: {
    sortBy?: string;
    sortAttribute?: string;
    sortOrder?: string;
  };
  aamUuid?: string;
}) => {
  const categoryData = createCategoryData(categories);
  const rangeData = createRangeData(rangeAttributes);
  const searchAttributes = createSearchAttributes(attributeHierarchy);
  const liveSince = createLiveSince(attributeHierarchy);
  const device = createDevice();
  const sortType = createSortType(sorting);
  const sortOrder = createSortOrder(sorting);

  return {
    intent: 'search',
    tenant: tenantContext.tenant.toUpperCase(),
    locale: tenantContext.locale,
    ...(fingerPrint && { fingerPrint }),
    ...(encryptedUserId && { encryptedUserId }),
    ...categoryData,
    ...rangeData,
    ...(liveSince && { liveSince }),
    ...(postcode && { searchPostalCode: postcode }),
    searchAttributes,
    ...(sortType && { sortType }),
    ...(sortOrder && { sortOrder }),
    ...(query && { searchQuery: query }),
    device,
    uuid: aamUuid,
  };
};

export default buildEvent;
