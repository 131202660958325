import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import trackingKeys from '@/client/constants/analytics/trackingKeys';
import LinkGenerator from '@/client/components/LinkGenerator/LinkGenerator';
import { Pill, Pills, Icon, Link } from '@hz-design-system/web-ui';

import { linkGeneratorHelper, generateLinkObject } from '@/utils/links/linkGeneratorHelper';

import filterChipsHelper from '@/client/utils/filterChipsHelper';
import pushToRouter from '@/client/utils/pushToRouter';
import getClientPageQueryObject from '@/client/utils/getClientPageQueryObject';

export type TFilterChipsProps = {
  searchRequestObject: TSearchRequestObject;
  categoriesById: TCategories;
  attributeHierarchy: TAttributeHierarchy;
  facets: TFacet[];
  showMobileFilterDialog?: () => void;
};

const FilterChips = ({
  searchRequestObject,
  attributeHierarchy,
  categoriesById,
  facets,
  showMobileFilterDialog,
}: TFilterChipsProps) => {
  const { t } = useI18nContext();
  const chips = filterChipsHelper(searchRequestObject, categoriesById, facets, t);

  const removeAllFiltersNavigation = () => {
    const options = {
      searchRequestObject,
      attributeHierarchy,
      removeAllOtherFilters: true,
      removeAllAttributes: true,
      eventAction: trackingKeys.FILTER_CHIPS.EVENT_ACTION.REMOVE_ALL,
      eventLabel: trackingKeys.FILTER_CHIPS.EVENT_LABEL,
    };

    const href = linkGeneratorHelper(options);
    if (href === '/') {
      window.location.assign('/');
    }
    const query = getClientPageQueryObject(generateLinkObject(options));
    pushToRouter({ query, href });
  };

  const l1CategoryNavigationLink = (chip) =>
    linkGeneratorHelper({
      searchRequestObject,
      ...chip.linkProps,
      eventAction: trackingKeys.FILTER_CHIPS.EVENT_ACTION.CHIP_ITEM,
      eventLabel: trackingKeys.FILTER_CHIPS.EVENT_LABEL,
    });

  return (
    <Pills className="stickyPills">
      <div className="pillsContainer">
        <button className="hz-Pill hz-Pill--iconStart allFiltersPill PillPrimary" onClick={showMobileFilterDialog}>
          <Icon name="filter" color="signal-action-default--inverse" />
          {t('filters.allFilters')}
        </button>
        {Boolean(chips.length) && (
          <div className="chipsSlider">
            <div className="innerWrapper">
              {chips.map((chip) => {
                if (chip.type === 'l1category') {
                  return (
                    <Pill key={chip.label} href={l1CategoryNavigationLink(chip)} isCloseable isActive>
                      {chip.label}
                    </Pill>
                  );
                }
                return (
                  <LinkGenerator
                    searchRequestObject={searchRequestObject}
                    {...(chip.linkProps as any)}
                    eventAction={trackingKeys.FILTER_CHIPS.EVENT_ACTION.CHIP_ITEM}
                    eventLabel={trackingKeys.FILTER_CHIPS.EVENT_LABEL}
                    key={chip.label}
                  >
                    <Pill isCloseable isActive>
                      {chip.label}
                    </Pill>
                  </LinkGenerator>
                );
              })}
            </div>
          </div>
        )}
        {chips.length > 1 && (
          <div className="deletePills">
            <Link className="chipsRemoveAll" onClick={removeAllFiltersNavigation} aria-hidden="true">
              {t('filters.clear')}
            </Link>
          </div>
        )}
      </div>
    </Pills>
  );
};

export default FilterChips;
