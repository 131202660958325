/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import Head from 'next/head';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import excludeTextAttributesFromNormalizing from '@/utils/seo/excludeTextAttributesFromNormalizing';

const SeoMetaTags: React.FC<TSeoMetaTags> = ({
  breadcrumbs,
  product,
  imageObject,
  canonicalUrl,
  title,
  description,
  content,
  altLocaleWithUrl,
  hrefLangUrl,
  localeAlt,
}) => {
  const {
    tenantContext: { locale },
  } = useContext(EnvironmentContext);

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta key="robots" name="robots" content={content} />

      {altLocaleWithUrl && (
        <>
          <link rel="alternate" hrefLang={locale.split('-')[0]} href={hrefLangUrl} />
          <link rel="alternate" hrefLang={localeAlt?.locale.split('-')[0]} href={altLocaleWithUrl.url} />
          <link rel="alternate" hrefLang="x-default" href={hrefLangUrl} />
        </>
      )}

      <link key="canonical" rel="canonical" href={excludeTextAttributesFromNormalizing(canonicalUrl)} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbs) }} />
      {Boolean(product) && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(product as object) }} />
      )}
      {Boolean(imageObject) && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(imageObject as object) }}
        />
      )}
    </Head>
  );
};

export default SeoMetaTags;
