import queryString from 'query-string';

const valueOrNull = <T>(param: T | undefined): T | null => (typeof param === 'undefined' ? null : param);

type TQueryObject = {
  l1CategoryId?: number;
  l2CategoryId?: number;
  sellerIds?: number[];
  query?: string;
  limit?: number;
  offset?: number;
  attributesById?: number[];
  attributesByKey?: string[];
  attributeRanges?: string[];
  sortBy?: string;
  sortOrder?: string;
  sortAttribute?: string;
  postcode?: string;
  distanceMeters?: number;
  viewOptions?: string;
  searchInTitleAndDescription?: boolean;
  asSavedSearch?: boolean;
  bypassSpellingSuggestion?: boolean;
  traits?: string[];
};

const searchRequestToQueryObject = (searchRequest: TSearchRequest): TQueryObject => {
  const qObject: TQueryObject = {};

  if (searchRequest.categories.l1Category) {
    qObject.l1CategoryId = searchRequest.categories.l1Category.id;
  }
  if (searchRequest.categories.l2Category) {
    qObject.l2CategoryId = searchRequest.categories.l2Category.id;
  }
  if (searchRequest.seller) {
    qObject.sellerIds = [searchRequest.seller];
  }
  if (searchRequest.searchQuery) {
    qObject.query = searchRequest.searchQuery;
    qObject.searchInTitleAndDescription = true;
  }
  if (searchRequest.pagination) {
    qObject.limit = searchRequest.pagination.limit;
    qObject.offset = searchRequest.pagination.offset;
  }
  if (searchRequest.attributesById.length) {
    qObject.attributesById = searchRequest.attributesById;
  }
  if (searchRequest.attributesByKey.length) {
    qObject.attributesByKey = searchRequest.attributesByKey.map((i) => `${i.attributeKey}:${i.attributeValueKey}`);
  }
  if (searchRequest.attributeRanges.length) {
    qObject.attributeRanges = searchRequest.attributeRanges.map(
      (i) => `${i.attributeKey}:${valueOrNull(i.from)}:${valueOrNull(i.to)}`,
    );
  }
  if (searchRequest.sortOptions.sortBy) {
    qObject.sortBy = searchRequest.sortOptions.sortBy;
  }
  if (searchRequest.sortOptions.sortOrder) {
    qObject.sortOrder = searchRequest.sortOptions.sortOrder;
  }
  if (searchRequest.sortOptions.sortAttribute) {
    qObject.sortAttribute = searchRequest.sortOptions.sortAttribute;
  }
  if (searchRequest.distance?.postcode) {
    qObject.postcode = searchRequest.distance.postcode;
  }
  if (searchRequest.distance?.distanceMeters) {
    qObject.distanceMeters = searchRequest.distance.distanceMeters;
  }
  if (searchRequest.viewOptions.kind) {
    qObject.viewOptions = searchRequest.viewOptions.kind;
  }
  if (searchRequest.asSavedSearch) {
    qObject.asSavedSearch = searchRequest.asSavedSearch;
  }
  if (searchRequest.bypassSpellingSuggestion) {
    qObject.bypassSpellingSuggestion = searchRequest.bypassSpellingSuggestion;
  }
  if (searchRequest.traits) {
    qObject.traits = searchRequest.traits;
  }

  return qObject;
};

export { searchRequestToQueryObject };

const searchRequestToQueryString = (searchRequest: TSearchRequest): string =>
  queryString.stringify(searchRequestToQueryObject(searchRequest), {
    arrayFormat: 'bracket',
  });

export default searchRequestToQueryString;
