import React from 'react';
import { I18nContextType, Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { SecondaryButton, ButtonIcon, Dropdown } from '@hz-design-system/web-ui';

import { linkGeneratorHelper, generateLinkObject } from '@/utils/links/linkGeneratorHelper';
import getClientPageQueryObject from '@/client/utils/getClientPageQueryObject';
import pushToRouter from '@/client/utils/pushToRouter';

type TSorterControlProps = {
  searchRequestObject: TSearchRequestObject;
  sortOptions: TSortOption[];
  withAllAttributes: boolean;
};

const getTranslation = (
  { sortBy, sortOrder, sortAttribute }: { sortBy: string; sortOrder: string; sortAttribute?: string },
  t: I18nContextType,
) => {
  const key = `${sortBy}.${sortOrder}${sortAttribute ? `.${sortAttribute}` : ''}`.toLowerCase();
  return t(`viewControls.sorting.${key}`);
};

const getTranslatedOptions = (options: TSortOption[], t: I18nContextType) =>
  options.map((option) => ({
    label: getTranslation(option, t),
    value: JSON.stringify(option),
  }));

const onSortChange = (searchRequestObject: TSearchRequestObject, sortOptions: string, withAllAttributes: boolean) => {
  const linkOptions = {
    searchRequestObject,
    sortOptions: JSON.parse(sortOptions),
    allowSavedSearch: true,
    withAllAttributes,
  };
  const href = linkGeneratorHelper(linkOptions);
  const query = getClientPageQueryObject(generateLinkObject(linkOptions));
  pushToRouter({ query, href });
};

const SorterControl = ({ searchRequestObject, sortOptions, withAllAttributes }: TSorterControlProps) => {
  const { t } = useI18nContext();
  const { sortOptions: selected } = searchRequestObject;
  const selectedOption = sortOptions.find((sortOption) => {
    if (sortOption.sortAttribute) {
      return sortOption.sortOrder === selected.sortOrder && sortOption.sortAttribute === selected.sortAttribute;
    }
    return sortOption.sortOrder === selected.sortOrder && sortOption.sortBy === selected.sortBy;
  });

  return (
    <>
      <Trans tagName="span" className="hz-ViewControl-sortByLabel" i18nKey="viewControls.sorting.sortBy" />
      <Dropdown
        value={selectedOption && JSON.stringify(selectedOption)}
        options={getTranslatedOptions(sortOptions, t)}
        onChange={(event) => onSortChange(searchRequestObject, event.target.value, withAllAttributes)}
      />
      <SecondaryButton className="hz-ViewControl-sort-button">
        <ButtonIcon name="sort" />
        <Trans i18nKey="viewControls.sorting.sort" />
      </SecondaryButton>
    </>
  );
};

export default SorterControl;
