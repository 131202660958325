import { currency } from '@ecg-marktplaats/aurora-js-formatters';
import carsFiltersConfig from '../carsFiltersConfig.json';
import CATEGORIES_L1 from '@/constants/categories';
const { filterGroups, priceToOptions, leasePriceToOptions } = carsFiltersConfig;

const COLLAPSED_FILTERS = ['brand', 'model', 'options'];

const transformRangeOptions = (rangeFilters, t, leasePriceOnly) => {
  const rangeConfigs = {
    PriceCents: {
      labelKey: leasePriceOnly ? 'PriceCentsLeaseLabel' : '',
      values: leasePriceOnly ? leasePriceToOptions : priceToOptions,
      valueFormatter: (value) => currency(value, true, true),
    },
  };

  rangeFilters.forEach(({ key, range, options }) => {
    const subfields = ['from', 'to'];

    if (!rangeConfigs[key]) {
      if (options.from?.[0].value === 0) {
        options.from[0].label = t('facets.all');
      }
      if (options.to?.[0].value === 0) {
        options.to[0].label = t('facets.all');
      }

      rangeConfigs[key] = {
        options,
      };
    } else {
      const rangeConfig = rangeConfigs[key];
      rangeConfigs[key].options = {};

      subfields.forEach((subfield) => {
        const rangeValue = range?.[subfield];
        let subfieldValues = [...rangeConfig.values];

        // Add custom value when present
        if (rangeValue && !subfieldValues.includes(rangeValue)) {
          subfieldValues.push(rangeValue);
          subfieldValues.sort((a, b) => a - b);
        }

        // Remove unneeded values because of selected values
        if (subfield === 'from' && range?.to) {
          subfieldValues = subfieldValues.filter((value) => value <= range.to);
        }
        if (subfield === 'to' && range?.from) {
          subfieldValues = subfieldValues.filter((value) => value >= range.from);
        }

        const subFieldOptions = subfieldValues.map((value) => ({
          value,
          label: `${rangeConfig.valueFormatter ? rangeConfig.valueFormatter(value) : value}${rangeConfig.suffix || ''}`,
        }));

        subFieldOptions.unshift({
          value: 0,
          label: t('facets.all'),
        });

        rangeConfigs[key].options[subfield] = subFieldOptions;
      });
    }
  });

  return rangeConfigs;
};

const sortOnHistogram = (a, b) => {
  if (!b.histogramCount || a.histogramCount > b.histogramCount) {
    return -1;
  }
  if (!a.histogramCount || a.histogramCount < b.histogramCount) {
    return 1;
  }
  return 0;
};

const transformFilterGroups = (facets) => {
  const sortedFacets = [...facets];
  COLLAPSED_FILTERS.forEach((filterKey) => {
    const facetIndex = sortedFacets.findIndex((facet) => facet.key === filterKey);
    if (facetIndex >= 0) {
      const facetToSort = facets[facetIndex];
      const shouldReOrder = !facetToSort.attributeGroup.some((a) => a.selected);
      sortedFacets.splice(facetIndex, 1, {
        ...facetToSort,
        ...(shouldReOrder && { attributeGroup: [...facetToSort.attributeGroup].sort(sortOnHistogram) }),
        collapsable: true,
      });
    }
  });

  const categoryFacet = facets.find(({ key }) => key === 'RelevantCategories');
  const selectedL2 = categoryFacet.categories.find(({ id, selected }) => selected && id !== CATEGORIES_L1.CARS);

  const facetGroups = {};
  Object.keys(filterGroups).forEach((groupKey) => {
    facetGroups[groupKey] = filterGroups[groupKey]
      .map((facetKey) =>
        selectedL2 && facetKey === 'variant'
          ? { key: 'variant', type: 'query' }
          : sortedFacets.find(({ key }) => key === facetKey),
      )
      .filter(Boolean);
  });

  return facetGroups;
};

export { transformRangeOptions, transformFilterGroups };
