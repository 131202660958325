import { logger } from '@lrp/logger';
import assembleSearchRequest from '@/HOC/utils/assembleSearchRequest';
import fetchSearchResults from '@/client/actions/search/fetchSearchResults';
import actionStatus from '@/client/actions/actionStatus';
import updateDataLayerAndInitGA from '@/HOC/utils/updateDataLayerAndInitGA';
import { ParsedUrlQuery } from 'querystring';

// eslint-disable-next-line max-params
export default async function fetchSearchResultsAndUpdateState(
  query: ParsedUrlQuery,
  locale,
  setRequestStatusState,
  setSearchRequestAndResponseState,
) {
  const updatedSearchObject = assembleSearchRequest(query);
  setRequestStatusState(actionStatus.fetching);

  try {
    const searchResults = await fetchSearchResults(updatedSearchObject);
    setRequestStatusState(actionStatus.success);
    setSearchRequestAndResponseState(searchResults);
    updateDataLayerAndInitGA(searchResults, locale);
  } catch (err) {
    logger.error('error in withSearchResults - fetchSearchResultsAndUpdateState', err);
    setRequestStatusState(actionStatus.failure);
  }
}
