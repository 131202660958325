import { defaults } from '@/constants/attributes/nonStandards';

type TAttributes = {
  [key: string]: string[] | { from?: number; to?: number } | TCategory;
};

const attributesGenerator = (
  attributeHierarchy: TAttributeHierarchy = {},
  attributeRanges: TAttributeRange[] = [],
  categories: TCategories = {},
): TAttributes | string => {
  const hasCategories = Object.keys(categories).length > 0;

  if (Object.values(attributeHierarchy).length === 0 && attributeRanges.length === 0 && !hasCategories) {
    return '';
  }

  const attributes: TAttributes = {};

  Object.keys(attributeHierarchy)
    .filter((key) => !defaults.attributes.includes(attributeHierarchy[key]?.[0].attributeValueKey))
    .forEach((key) => {
      attributes[key] = [];
      attributeHierarchy[key].forEach((attr: { attributeValueKey: string }) => {
        (attributes[key] as string[]).push(attr.attributeValueKey);
      });
    });

  attributeRanges.forEach(({ attributeKey, ...range }) => {
    attributes[attributeKey] = range;
  });

  if (categories.l2Category) {
    attributes.l2Category = categories.l2Category;
  }

  return attributes;
};

export default attributesGenerator;
