import React, { memo } from 'react';
import isEqual from 'lodash.isequal';
import { Icon } from '@hz-design-system/web-ui';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { Trans } from '@ecg-marktplaats/js-react-i18n';
import { ListingSellerExternalLink } from '@/client/components/Listings/atoms';
import { imageFormatter } from '@/client/utils/formatters';
import ListingGalleryViewFigure from '@/client/components/Listings/molecules/ListingGalleryViewFigure';
import ListingVipLink from '@/client/components/Listings/atoms/ListingVipLink';
import { Attribute } from '@/client/types/Listings';
import { TImage } from '@/client/types/Images';

interface Location {
  label: string;
  distance?: string;
}

interface ListingGalleryViewContentProps {
  location: Location;
  title: string;
  subtitle?: string;
  vipUrl: string;
  pictures?: TImage[];
  price: string;
  date: string;
  sellerInformation: TSellerInformation;
  pageLocation?: string;
  correlationId?: string;
  loadingType?: 'eager' | 'lazy';
  decodingType?: 'sync' | 'async' | 'auto';
  fetchPriority?: 'auto' | 'high' | 'low';
  trackingData?: string;
  opvalStickerText?: string;
  priorityProduct?: string;
  isFavorite?: boolean;
  onFavorite: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  l1Category?: number;
  categories?: TCategories;
  extendedAttributes?: Attribute[];
  thinContent?: boolean;
}

const ListingGalleryViewContent: React.FC<ListingGalleryViewContentProps> = ({
  location,
  title,
  subtitle,
  price,
  sellerInformation,
  date,
  pictures,
  vipUrl,
  trackingData,
  correlationId,
  pageLocation,
  opvalStickerText = '',
  priorityProduct,
  isFavorite,
  onFavorite,
  l1Category,
  categories,
  extendedAttributes,
  thinContent,
  loadingType,
  decodingType,
  fetchPriority,
}) => {
  return (
    <ListingVipLink
      vipUrl={vipUrl}
      trackingData={trackingData}
      correlationId={correlationId}
      pageLocation={pageLocation}
      thinContent={thinContent}
    >
      <ListingGalleryViewFigure
        images={imageFormatter(pictures ?? [], title, categories, extendedAttributes)}
        loadingType={loadingType}
        decodingType={decodingType}
        fetchPriority={fetchPriority}
        opvalStickerText={opvalStickerText}
        isFavorite={isFavorite}
        onFavorite={onFavorite}
        l1Category={l1Category}
      />
      <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-content' })}>
        <div>
          <h3 className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-title' })}>
            {title}
            {subtitle && <span className="hz-Text hz-Text--bodyLarge u-colorTextSecondary">{` ${subtitle}`}</span>}
          </h3>
          <span
            className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-price', custom: 'hz-text-price-label' })}
          >
            {price}
          </span>
        </div>
        <div>
          {priorityProduct && (
            <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-priority' })}>
              <Trans tagName="span" i18nKey={`priorityProduct.${priorityProduct}`} />
            </div>
          )}
          <span className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-location' })}>
            <Icon name="location" size="m" />
            {location.label} {location.distance}
          </span>
          <span suppressHydrationWarning className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-date' })}>
            <Icon name="clock" size="m" />
            {date}
          </span>
          <span className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-seller-link' })}>
            {sellerInformation && sellerInformation.showWebsiteUrl && (
              <ListingSellerExternalLink url={sellerInformation.sellerWebsiteUrl} className="hz-TextLink">
                <Icon name="website" size="m" color="signal-link-default" />
                <Trans tagName="span" i18nKey="listings.gotoWebsite" />
              </ListingSellerExternalLink>
            )}
          </span>
        </div>
      </div>
    </ListingVipLink>
  );
};

// todo: find which properties are really needs to be checked for preventing rerendering
function arePropsEqual(prevProps: ListingGalleryViewContentProps, nextProps: ListingGalleryViewContentProps) {
  return isEqual(prevProps, nextProps);
}

export default memo(ListingGalleryViewContent, arePropsEqual);
