import React, { useEffect, useState } from 'react';

interface BannerProps {
  position: string;
  type: string;
}

const BannerListItem: React.FC<BannerProps> = ({ position, type }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <div className={`hz-Banner hz-Banner--${type}`}>
      <div id={`banner-${position}`}></div>
    </div>
  );
};

export default BannerListItem;
