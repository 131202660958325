import React from 'react';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import ListingImage from '@/client/components/Listings/atoms/ListingImage';
import { ListingImageNone, ListingOpvalSticker } from '@/client/components/Listings/atoms';
import { formatImageUrl } from '@ecg-marktplaats/media-util-js-lib';
import { MAIN_IMAGE_SIZE_GALLERY } from '@/client/constants/listings';
import { RoundIconButton } from '@hz-design-system/web-ui';
import CATEGORIES_L1 from '@/constants/categories';
import { TImage } from '@/client/types/Images';

const figureClassName = getSuitCSSClasses({ namespace: 'hz', name: 'Listing-image-container' });

interface ListingFigureProps {
  images: TImage[];
  loadingType?: 'eager' | 'lazy';
  decodingType?: 'sync' | 'async' | 'auto';
  fetchPriority?: 'auto' | 'high' | 'low';
  opvalStickerText?: string;
  isFavorite?: boolean;
  onFavorite?: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  l1Category?: number;
}

const ListingFigure: React.FC<ListingFigureProps> = ({
  images,
  loadingType,
  decodingType,
  fetchPriority,
  opvalStickerText = '',
  isFavorite,
  onFavorite,
  l1Category,
}) => {
  const firstImage = images[0];

  if (firstImage && firstImage.url) {
    firstImage.imageSource = formatImageUrl(firstImage.url, MAIN_IMAGE_SIZE_GALLERY, firstImage.sizes);
  }

  return (
    <figure className={figureClassName}>
      {opvalStickerText && <ListingOpvalSticker isGalleryView>{opvalStickerText}</ListingOpvalSticker>}
      {images.length === 0 && (
        <div className="hz-Listing-image-none-item">
          <ListingImageNone />
        </div>
      )}
      {images.length > 0 && (
        <ListingImage
          image={firstImage}
          loadingType={loadingType}
          decodingType={decodingType}
          fetchPriority={fetchPriority}
        />
      )}
      {l1Category === CATEGORIES_L1.CARS && (
        <RoundIconButton.Secondary
          name={isFavorite ? 'heart_filled' : 'heart'}
          className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-favorite-button' })}
          onClick={onFavorite}
        />
      )}
    </figure>
  );
};

export default ListingFigure;
