import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import experiments from '@/utils/labs/experiments';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { linkGeneratorHelper, generateLinkObject } from '@/utils/links/linkGeneratorHelper';

import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import getClientPageQueryObject from '@/client/utils/getClientPageQueryObject';
import Heading from '@/client/components/FilterSidebar/atoms/Heading';
import pushToRouter from '@/client/utils/pushToRouter';

import CATEGORIES_L1 from '@/constants/categories';

const CENTS_TO_EUR = 100;
const patternCurrency = /^\d*([.]\d{0,2})?$/; // note the type='number' field converts any comma to a dot
const patternNumber = /^\d+$/;

interface Range {
  from: number;
  to: number;
}

export interface AttributeRangeFacetProps {
  label?: string;
  inputKey: string;
  searchRequestObject: TSearchRequestObject;
  range: Range;
}

const ranges: Record<
  string,
  { min: number; max: number; pattern: RegExp; propToStateValue: (value: number) => string }
> = {
  PriceCents: {
    min: 0,
    max: Number.MAX_VALUE,
    pattern: patternCurrency,
    propToStateValue: (value) => (value ? `${value / CENTS_TO_EUR}` : ''),
  },
  constructionYear: {
    min: 1900,
    max: new Date().getFullYear() + 1,
    pattern: patternNumber,
    propToStateValue: (value) => (value ? `${value}` : ''),
  },
  mileage: {
    min: 0,
    max: Number.MAX_VALUE,
    pattern: patternNumber,
    propToStateValue: (value) => (value ? `${value}` : ''),
  },
};

const AttributeRangeFacet: React.FC<AttributeRangeFacetProps> = ({
  searchRequestObject,
  inputKey,
  range = { from: 0, to: 0 },
  label,
}) => {
  const { t } = useI18nContext();
  const { labsConfig } = useContext(EnvironmentContext);
  const { isFetchResultsOnceEnabled, isFetchResultsOnceAllCatEnabled } = experiments({ labsConfig });
  const withAllAttributes =
    isFetchResultsOnceAllCatEnabled ||
    (isFetchResultsOnceEnabled && searchRequestObject.categories?.l1Category?.id === CATEGORIES_L1.DAMES_KLEREN);
  const [fromState, setFromState] = useState({
    value: ranges[inputKey].propToStateValue(range.from),
    valid: true,
  });
  const [toState, setToState] = useState({
    value: ranges[inputKey].propToStateValue(range.to),
    valid: true,
  });
  const [valid, setValid] = useState(true);

  useEffect(() => {
    setFromState({
      value: ranges[inputKey].propToStateValue(range.from),
      valid: true,
    });
    setToState({
      value: ranges[inputKey].propToStateValue(range.to),
      valid: true,
    });
  }, [inputKey, range.from, range.to]);

  const checkValidity = (value: string) => {
    const isEmpty = value === '';

    const isInRange = Number(value) >= ranges[inputKey].min && Number(value) <= ranges[inputKey].max;
    const isConformPattern = ranges[inputKey].pattern.test(value);
    const isValidInput = isInRange && isConformPattern;

    return isEmpty || isValidInput;
  };

  const normalizeValue = (attributeKey: string, value: string) => {
    const multiplier = attributeKey === 'PriceCents' ? CENTS_TO_EUR : 1;
    return value !== '' ? Math.round(Number(value) * multiplier) : null;
  };

  const rangeFilterHandler = () => {
    const otherRanges = searchRequestObject.attributeRanges.filter((attribute) => attribute.attributeKey !== inputKey);
    const options = {
      searchRequestObject,
      withAllAttributes,
      rangeAttributes: [
        ...otherRanges,
        {
          attributeKey: inputKey,
          from: normalizeValue(inputKey, fromState.value),
          to: normalizeValue(inputKey, toState.value),
        },
      ],
    };
    const href = linkGeneratorHelper(options);
    const pageQuery = getClientPageQueryObject(generateLinkObject(options));
    pushToRouter({ query: pageQuery, href });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && valid) {
      rangeFilterHandler();
    }
  };

  const handleOnChangeTo = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>, onBlur?: boolean) => {
    const newState = {
      value,
      valid: checkValidity(value),
    };

    const lowerLimitsSmallerThanUpperLimit = Number(fromState.value) <= Number(newState.value);
    const isValidLimits = lowerLimitsSmallerThanUpperLimit || fromState.value === '' || newState.value === '';
    const isValid = fromState.valid && newState.valid && isValidLimits;

    setValid(isValid);
    setToState(newState);
    if (onBlur && isValid) {
      rangeFilterHandler();
    }
  };

  const handleOnChangeFrom = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>, onBlur?: boolean) => {
    const newState = {
      value,
      valid: checkValidity(value),
    };

    const lowerLimitsSmallerThanUpperLimit = Number(newState.value) <= Number(toState.value);
    const isValidLimits = lowerLimitsSmallerThanUpperLimit || newState.value === '' || toState.value === '';
    const isValid = newState.valid && toState.valid && isValidLimits;

    setValid(isValid);
    setFromState(newState);
    if (onBlur && isValid) {
      rangeFilterHandler();
    }
  };

  return (
    <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter' })}>
      <Heading.H5>{t(`facets.${inputKey}`, label)}</Heading.H5>
      <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter-fields' })}>
        <TextField
          name="from"
          onChange={handleOnChangeFrom}
          onBlur={(event) => handleOnChangeFrom(event, true)}
          placeholder={t('facets.from')}
          onKeyPress={handleKeyPress}
          value={fromState.value}
          type="number"
        />
        <TextField
          name="to"
          onChange={handleOnChangeTo}
          onBlur={(event) => handleOnChangeTo(event, true)}
          placeholder={t('facets.to')}
          onKeyPress={handleKeyPress}
          value={toState.value}
          type="number"
        />
      </div>
    </div>
  );
};

export default AttributeRangeFacet;
