import React, { memo } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { BasicModalWithFooter } from '@hz-design-system/web-ui';

import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import RegularFilters from '@/client/components/FilterSidebar/organisms/RegularFilters';
import RegularCarsFilters from '@/client/components/FilterSidebar/organisms/RegularCarsFilters';

export interface FilterSideBarProps {
  searchRequestObject: TSearchRequestObject;
  isMobile?: boolean;
  isMobileFilterDialogOpen: boolean;
  onHideMobileFilterDialog: () => void;
  facets: TFacet[];
  searchDistanceOptions: { name: string; value: number }[];
  sharedDomainCookie: string;
  totalResults: number;
  carsFilters: boolean;
}

const FilterSidebar: React.FC<FilterSideBarProps> = ({
  searchRequestObject,
  isMobile = false,
  isMobileFilterDialogOpen = false,
  onHideMobileFilterDialog,
  facets,
  searchDistanceOptions,
  sharedDomainCookie,
  totalResults,
  carsFilters,
}) => {
  const { t } = useI18nContext();

  if (isMobile && !isMobileFilterDialogOpen) {
    return null;
  }

  if (isMobile) {
    return (
      <BasicModalWithFooter
        size="full"
        className={getSuitCSSClasses({
          namespace: 'hz',
          name: 'Filters-dialog',
          custom: 'lrp-mobile-dialog',
        })}
        onClose={onHideMobileFilterDialog}
        title={t('facets.title')}
        confirm={{
          text: t('facets.showResults', { count: totalResults, totalResults }),
          onClick: onHideMobileFilterDialog,
        }}
      >
        <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filters-content' })}>
          {!carsFilters && (
            <RegularFilters
              facets={facets}
              isMobile={isMobile}
              searchRequestObject={searchRequestObject}
              sharedDomainCookie={sharedDomainCookie}
              searchDistanceOptions={searchDistanceOptions}
              totalResults={totalResults}
            />
          )}
          {carsFilters && (
            <RegularCarsFilters
              facets={facets}
              isMobile={isMobile}
              searchRequestObject={searchRequestObject}
              sharedDomainCookie={sharedDomainCookie}
              searchDistanceOptions={searchDistanceOptions}
              totalResults={totalResults}
            />
          )}
        </div>
      </BasicModalWithFooter>
    );
  }

  return (
    <div
      className={getSuitCSSClasses({
        namespace: 'hz',
        name: 'Filters',
      })}
    >
      {!carsFilters && (
        <RegularFilters
          facets={facets}
          title={t('facets.title')}
          isMobile={isMobile}
          searchRequestObject={searchRequestObject}
          sharedDomainCookie={sharedDomainCookie}
          searchDistanceOptions={searchDistanceOptions}
          totalResults={totalResults}
        />
      )}
      {carsFilters && (
        <RegularCarsFilters
          facets={facets}
          title={t('facets.title')}
          isMobile={isMobile}
          searchRequestObject={searchRequestObject}
          sharedDomainCookie={sharedDomainCookie}
          searchDistanceOptions={searchDistanceOptions}
          totalResults={totalResults}
        />
      )}
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  /* eslint-disable operator-linebreak */
  return (
    prevProps.isMobile === nextProps.isMobile &&
    prevProps.isMobileFilterDialogOpen === nextProps.isMobileFilterDialogOpen &&
    JSON.stringify(prevProps.facets) === JSON.stringify(nextProps.facets) &&
    JSON.stringify(prevProps.searchRequestObject) === JSON.stringify(nextProps.searchRequestObject)
  );
  /* eslint-enable operator-linebreak */
}

export default memo(FilterSidebar, arePropsEqual);
