import React from 'react';
import { DumbSelectionGroup } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import Heading from '@/client/components/FilterSidebar/atoms/Heading';
import InlineInput from '@/client/components/FilterSidebar/atoms/InlineInput';
import { attributeKeys } from '@/constants/attributes/nonStandards';

type TAttributeGroupSingleSelectFacetProps = {
  searchRequestObject: TSearchRequestObject;
  label: string;
  inputKey: string;
  attributeGroup: TSelectionGroupFacet['attributeGroup'];
  preventScroll?: boolean;
};

const AttributeGroupSingleSelectFacet = ({
  label,
  attributeGroup,
  inputKey,
  searchRequestObject,
  preventScroll,
}: TAttributeGroupSingleSelectFacetProps) => {
  const { t } = useI18nContext();
  const selectedItemOrder = attributeGroup.findIndex((attribute) => attribute.selected);
  const isLastItemSelected = selectedItemOrder === attributeGroup.length - 1;
  const customHistogramAttributes = inputKey === attributeKeys.OFFERED_SINCE || inputKey === attributeKeys.LANGUAGE;
  const showHistograms = customHistogramAttributes ? isLastItemSelected : true;

  return (
    <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter' })}>
      <Heading.H5>{t(`facets.${inputKey}`, label)}</Heading.H5>
      <DumbSelectionGroup>
        {attributeGroup.map(
          ({
            attributeValueKey,
            attributeValueLabel,
            histogramCount = 0,
            attributeValueId,
            selected,
            default: isDefault,
          }) => {
            const attrLabel = attributeValueLabel || '';
            const facetLabel = t(`facets.${attributeValueKey}`, attrLabel);
            const attributes = {};
            attributes[inputKey] = {
              attributeValueKey,
              attributeValueId: attributeValueId || null,
              attributeValueLabel: facetLabel,
              isDefault,
            };

            return (
              <InlineInput
                key={attributeValueKey}
                count={showHistograms ? histogramCount : -1}
                valueKey={attributeValueKey}
                attributeKey={inputKey}
                label={facetLabel}
                type="radio"
                checked={selected}
                attributes={attributes}
                isSingleSelect
                disabled={histogramCount === 0}
                searchRequestObject={searchRequestObject}
                preventScroll={preventScroll}
              />
            );
          },
        )}
      </DumbSelectionGroup>
    </div>
  );
};

export default AttributeGroupSingleSelectFacet;
