import { getSeoFriendlyCategoryName } from '@ecg-marktplaats/aurora-node-data-layer';
import { CategoryObject } from '@ecg-marktplaats/aurora-node-data-layer/dist/types';

interface DataLayerCategory {
  id: number;
  n?: string;
}

interface DataLayerResult {
  c: DataLayerCategory;
  l1: DataLayerCategory;
  l2: DataLayerCategory;
  sfc: CategoryObject;
}

/**
 * Converts the supplied categories object to dataLayer format
 * Assigns 'null' instead of -1 to undefined categories' ids
 *
 * @param {Categories} categories
 * @return {DataLayerResult}
 */
const categoriesToDataLayerCategories = (categories: TCategories): DataLayerResult => {
  const { l1Category, l2Category } = categories;

  let cField: DataLayerCategory = { id: 0, n: '' };
  let l1Field: DataLayerCategory = { id: 0, n: '' };
  let l2Field: DataLayerCategory = { id: 0, n: '' };

  if (!l1Category) {
    return { c: cField, l1: l1Field, l2: l2Field, sfc: getSeoFriendlyCategoryName(l1Field) };
  }

  if (l2Category) {
    cField = { id: l2Category.id, n: l2Category.key };
    l1Field = { id: l1Category.id, n: l1Category.key };
    l2Field = { id: l2Category.id, n: l2Category.key };
  } else if (l1Category) {
    cField = { id: l1Category.id, n: l1Category.key };
    l1Field = { id: l1Category.id, n: l1Category.key };
  }

  return { c: cField, l1: l1Field, l2: l2Field, sfc: getSeoFriendlyCategoryName(l1Field) };
};

export default categoriesToDataLayerCategories;
