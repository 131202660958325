import React from 'react';

interface BannerProps {
  position: string;
  type: string;
  loading?: boolean;
  mobileOnly?: boolean;
  isSOI?: boolean;
}

const Banner: React.FC<BannerProps> = ({ position, type, loading, mobileOnly = false, isSOI = false }) => {
  const loadingClassName = loading ? 'loading' : '';

  return (
    <div suppressHydrationWarning={true} className={`hz-Banner hz-Banner--${type}`}>
      <div
        suppressHydrationWarning={true}
        id={`banner-${position}`}
        className={`${loadingClassName} ${mobileOnly ? 'mobileOnly' : ''} ${isSOI ? 'soiBanner' : ''}`}
      />
    </div>
  );
};

export default Banner;
