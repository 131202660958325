import getParamsFromHash from '@/HOC/utils/urlHash/getParamsFromHash';
import removeDuplicates from '@/HOC/utils/arrays/removeDuplicates';
import getViewOptionsFromCookie from '@/HOC/utils/viewOptionCookie';
import { getCookie } from '@/client/utils/cookies';
import formatPostCode from '@/utils/urlHash/formatPostCode';
import { defaults } from '@/constants/attributes/nonStandards';

import COOKIES from '@/constants/cookies';
import { ParsedUrlQuery } from 'querystring';

const getPostcodeFromCookie = () => {
  const cookieValue = getCookie(COOKIES.SEARCH_POST_CODE);

  return cookieValue ? formatPostCode(cookieValue) : '';
};

const determineViewKind = (viewOptionsFromHash: { kind: string }) => {
  if (viewOptionsFromHash && viewOptionsFromHash.kind) {
    return viewOptionsFromHash.kind;
  }

  const viewKindFromCookie = getViewOptionsFromCookie();
  if (viewKindFromCookie) {
    return viewKindFromCookie;
  }

  return defaults.view;
};

export default function assembleSearchRequest(query: ParsedUrlQuery): TSearchRequest {
  const {
    l1CategoryId,
    l2CategoryId,
    sellerId,
    searchQuery,
    offset,
    limit,
    attributesValuesIds,
    textAttributesValuesLabels,
    textAttributesValuesKeys,
  } = query;

  const hashParams = getParamsFromHash(window.location.hash);

  if (textAttributesValuesKeys) {
    if (Array.isArray(textAttributesValuesKeys) && Array.isArray(textAttributesValuesLabels)) {
      textAttributesValuesKeys.forEach((value, i) => {
        hashParams.attributesByKey.push({
          attributeKey: value,
          attributeValueKey: textAttributesValuesLabels[i] || '',
        });
      });
    } else {
      hashParams.attributesByKey.push({
        attributeKey: textAttributesValuesKeys as string,
        attributeValueKey: textAttributesValuesLabels as string,
      });
    }
  }

  return {
    categories: {
      ...(l1CategoryId && { l1Category: { id: Number(l1CategoryId) } }),
      ...(l2CategoryId && { l2Category: { id: Number(l2CategoryId) } }),
    },
    ...(sellerId && { seller: Number(sellerId) }),
    searchQuery: hashParams.query || (searchQuery as string), // get the query from router first
    attributes: hashParams.attributes,
    attributeLabels: hashParams.attributeLabels,
    attributesById: removeDuplicates(
      hashParams.attributesById.concat(
        typeof attributesValuesIds === 'string' ? Number(attributesValuesIds) : (attributesValuesIds || []).map(Number),
      ),
    ),
    attributesByKey: removeDuplicates(
      hashParams.attributesByKey,
      (
        x: { attributeKey: string; attributeValueKey: string },
        y: { attributeKey: string; attributeValueKey: string },
      ) => x.attributeKey === y.attributeKey && x.attributeValueKey === y.attributeValueKey,
    ),
    attributeRanges: hashParams.attributeRanges,
    sortOptions: hashParams.sortOptions,
    pagination: {
      offset: Number(offset),
      limit: hashParams.limit ? Number(hashParams.limit) : Number(limit),
    },
    distance: {
      postcode: hashParams.distance.postcode || getPostcodeFromCookie(),
      distanceMeters: hashParams.distance.distanceMeters,
    },
    viewOptions: {
      kind: determineViewKind(hashParams.viewOptions),
    },
    searchInTitleAndDescription: true,
    asSavedSearch: hashParams.asSavedSearch,
    bypassSpellingSuggestion: hashParams.bypassSpellingSuggestion,
    traits: hashParams.traits,
  };
}
