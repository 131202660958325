import { useCallback } from 'react';

import { linkGeneratorHelper, generateLinkObject } from '@/utils/links/linkGeneratorHelper';
import getClientPageQueryObject from '@/client/utils/getClientPageQueryObject';
import pushToRouter from '@/client/utils/pushToRouter';
import { setCookie } from '@/client/utils/cookies';
import COOKIES from '@/constants/cookies';

const useViewControls = (
  searchRequestObject: TSearchRequestObject,
  isAllowSavedSearch: boolean,
  withAllAttributes?: boolean,
) => {
  const handleViewOptionsChange = useCallback(
    (kind) => {
      const linkOptions = {
        searchRequestObject,
        view: kind,
        allowSavedSearch: isAllowSavedSearch,
        isViewOptionsClicked: true,
        withAllAttributes,
      };
      const href = linkGeneratorHelper(linkOptions);
      const query = getClientPageQueryObject(generateLinkObject(linkOptions));

      setCookie(COOKIES.VIEW_TYPE, kind);

      pushToRouter({
        query,
        href,
      });
    },
    [searchRequestObject, isAllowSavedSearch, withAllAttributes],
  );

  return {
    handleViewOptionsChange,
  };
};

export default useViewControls;
