import combineRangeAttributes from './combineRangeAttributes';
import hashMapper from '@/HOC/utils/urlHash/hashMapper';
import splitHashOptions from '@/HOC/utils/urlHash/splitHashOptions';

const getParamsFromHash = (hashString: string) => {
  if (!hashString) {
    return {
      attributes: [],
      query: undefined,
      attributeLabels: [],
      attributesById: [],
      attributesByKey: [],
      attributeRanges: [],
      sortOptions: {
        sortBy: '',
        sortOrder: '',
        sortAttribute: '',
      },
      distance: {
        postcode: '',
        distanceMeters: 0,
      },
      viewOptions: {
        kind: '',
      },
      asSavedSearch: false,
      bypassSpellingSuggestion: false,
      traits: [],
      limit: 30,
    };
  }

  const splitParams = splitHashOptions(hashString);
  const result = hashMapper(splitParams);

  if (result.attributeRanges?.length) {
    result.attributeRanges = combineRangeAttributes(result.attributeRanges);
  }

  return result;
};

export default getParamsFromHash;
