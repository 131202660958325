import React from 'react';
import LinkGenerator from '@/client/components/LinkGenerator/LinkGenerator';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

type TProps = {
  correctedQuery: string;
  originalQuery: string;
  searchRequestObject: TSearchRequestObject;
};

const CorrectedKeyword = ({ correctedQuery, searchRequestObject, originalQuery }: TProps) => {
  if (!correctedQuery) return null;

  return (
    <>
      <Trans tagName="span" i18nKey="breadcrumbs.originalSearch" />
      <LinkGenerator searchRequestObject={searchRequestObject} query={originalQuery} bypassSpellingSuggestion>
        &nbsp;{originalQuery}
      </LinkGenerator>
      <Trans tagName="span" i18nKey="breadcrumbs.originalSearch_end" />
    </>
  );
};

export default CorrectedKeyword;
