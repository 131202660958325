import React, { useContext, useState, useEffect } from 'react';
import { Dropdown } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import experiments from '@/utils/labs/experiments';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { linkGeneratorHelper, generateLinkObject } from '@/utils/links/linkGeneratorHelper';

import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import getClientPageQueryObject from '@/client/utils/getClientPageQueryObject';
import pushToRouter from '@/client/utils/pushToRouter';

import HelpTooltip from '../atoms/HelpTooltip';
import CATEGORIES_L1 from '@/constants/categories';

type TAttributeRangeFacetDropdownProps = {
  searchRequestObject: TSearchRequestObject;
  attributeKey: string;
  options: {
    from?: [];
    to?: [];
  };
  range?: {
    from?: number;
    to?: number;
  };
  label: string;
  labelKey: string;
  preventScroll: boolean;
  isMobile: boolean;
};

const AttributeRangeFacetDropdown = ({
  searchRequestObject,
  attributeKey = '',
  options,
  range = {},
  label = '',
  labelKey,
  preventScroll,
  isMobile,
}: TAttributeRangeFacetDropdownProps) => {
  const { t, tExists } = useI18nContext();
  const { labsConfig } = useContext(EnvironmentContext);
  const { isFetchResultsOnceEnabled, isFetchResultsOnceAllCatEnabled } = experiments({ labsConfig });
  const withAllAttributes =
    isFetchResultsOnceAllCatEnabled ||
    (isFetchResultsOnceEnabled && searchRequestObject.categories?.l1Category?.id === CATEGORIES_L1.DAMES_KLEREN);
  const [rangeState, setRangeState] = useState({
    attributeKey,
    from: range.from,
    to: range.to,
  });
  const titleLabelKey = labelKey || attributeKey;
  const helpTextKey = `facets.help.${attributeKey}`;
  const withAttributeHelpText = tExists(helpTextKey);

  useEffect(() => {
    if (Object.keys(range).length === 0 && !rangeState.from && !rangeState.to) {
      return;
    }

    setRangeState({
      attributeKey,
      from: range.from,
      to: range.to,
    });
  }, [attributeKey, range, rangeState.from, rangeState.to]);

  const rangeFilterHandler = ({ attributeKey: attrKey, from, to }) => {
    const otherRanges = searchRequestObject.attributeRanges.filter((attribute) => attribute.attributeKey !== attrKey);
    const linkOptions = {
      searchRequestObject,
      withAllAttributes,
      rangeAttributes: [
        ...otherRanges,
        {
          attributeKey: attrKey,
          ...(from && { from }),
          ...(to && { to }),
        },
      ],
    };

    if (preventScroll) {
      window.preventScroll = true;
    }

    const href = linkGeneratorHelper(linkOptions);
    // @ts-ignore
    const pageQuery = getClientPageQueryObject(generateLinkObject(linkOptions));
    pushToRouter({ query: pageQuery, href });
  };

  const handleOnChange = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>, subfield: string) => {
    const updatedState = { ...rangeState };
    updatedState[subfield] = Number(value);
    setRangeState(updatedState);
    rangeFilterHandler(updatedState);
  };

  return (
    <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter' })}>
      <div className="hz-Filter-heading hz-Filter-heading--5">
        {t(`facets.${titleLabelKey}`, label)}
        {withAttributeHelpText && <HelpTooltip helpTextKey={helpTextKey} isMobile={isMobile} />}
      </div>
      <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter-fields', modifiers: ['dropdown'] })}>
        {Boolean(options.from) && (
          <Dropdown
            id={attributeKey}
            className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter-dropdown' })}
            options={options.from}
            placeHolderText={t('facets.rangeFrom')}
            onChange={(e) => handleOnChange(e, 'from')}
            value={rangeState['from'] ? rangeState['from'].toString() : ''}
          />
        )}
        {Boolean(options.to) && (
          <Dropdown
            id={attributeKey}
            className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter-dropdown' })}
            options={options.to}
            placeHolderText={t('facets.rangeTo')}
            onChange={(e) => handleOnChange(e, 'to')}
            value={rangeState['to'] ? rangeState['to'].toString() : ''}
          />
        )}
      </div>
    </div>
  );
};

export default AttributeRangeFacetDropdown;
